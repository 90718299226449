import React from 'react';
import { useTranslation } from "react-i18next";

// @ts-ignore
import { Layout } from '@abyss/web/ui/Layout';
// @ts-ignore
import { Text } from '@abyss/web/ui/Text';

export const Footer = () => {
    const { t, i18n } = useTranslation();

    const date = new Date();

    var footerText = t('poweredBySecure');
    var footerBusiness = t('optumCopyright', { year: date.getFullYear() });

    return (
        <div style={{ border: '0px', padding: '15px', margin: '4px' }}>
            <Layout.Stack alignLayout="center">
                <Text id="footerText" color="#999999">{footerText}</Text>
                <Text id="footerBusiness" color="#999999">{footerBusiness}</Text>
            </Layout.Stack>
        </div>
    );
}