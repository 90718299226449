import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useTranslation } from "react-i18next";

// @ts-ignore
import { PageHeader } from '@abyss/web/ui/PageHeader';
// @ts-ignore
import { NavMenu } from '@abyss/web/ui/NavMenu';

export const HeaderNavMenu = () => {
    const { isAuthenticated, displayName } = useAuth();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const nav = useNavigate();
    const topMenuItems = [];
    const [isInitialView, setInitialView] = useState(true);
    const [navigationCount, setNavigationCount] = useState(0);
    const navToNewPassword = () => { nav('/new-password') };
    const navToTestPassword = () => { nav('/test-password') };
    const navToLogout = () => { nav('/logout') };

    if (isAuthenticated) {
        if (window.location.href.indexOf("test-password") > -1) {
            const newPasswordText = t('setNewPassword');
            topMenuItems.push({
                id: 'newPasswordTopMenu',
                title: newPasswordText,
                onClick: navToNewPassword
            });
        };

        if (window.location.href.indexOf("new-password") > -1) {
            const testPasswordText = t('testPassword');
            topMenuItems.push({
                id: 'testPasswordTopMenu',
                title: testPasswordText,
                onClick: navToTestPassword
            });
        };

        const logoutText = t('logout');
        if (isInitialView && (window.location.pathname === "/" || window.location.pathname === "/home")) {
            topMenuItems.push({
                id: 'logoutTopMenu',
                title: logoutText,
                //href: '/logout'
                onClick: navToLogout
            });
        }
        else {
            topMenuItems.push({
                title: displayName,
                content: (
                    <NavMenu.Columns>
                        <NavMenu.Column>
                            <NavMenu.Item
                                id="logoutNavMenuItem"
                                title={logoutText}
                                //href="/logout"
                                onClick={navToLogout}
                            />
                        </NavMenu.Column>
                    </NavMenu.Columns>
                ),
            });
        }
    }
    else {
        const loginText = t('login');
        topMenuItems.push({ title: loginText, href: '/login' });
    }

    React.useEffect(() => {
        // runs on location, i.e. route, change
        setNavigationCount(navigationCount + 1);
        if (navigationCount > 0) {
            setInitialView(false);
        }
    }, [location])

    return (
        <PageHeader
            logo={<img src="optumhelpme-logo.svg" alt="Optum HelpMe"/>}
            logoRedirect="/"
            topMenuItems={topMenuItems}
            fullWidth
            menuPosition={'right'}
        />
    );
}
