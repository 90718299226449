import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useAuth } from '../context/AuthContext';
import { useTranslation } from "react-i18next";
import { useTestPassword } from '../hooks/PasswordHooks';
import { PasswordParams } from '../types/PasswordParams';
import { ResultPanel } from './ResultPanel'

// @ts-ignore
import { useForm } from '@abyss/web/hooks/useForm';
// @ts-ignore
import { FormProvider } from '@abyss/web/ui/FormProvider'
// @ts-ignore
import { Layout } from '@abyss/web/ui/Layout';
// @ts-ignore
import { Heading } from '@abyss/web/ui/Heading';
// @ts-ignore
import { Label } from '@abyss/web/ui/Label';
// @ts-ignore
import { Text } from '@abyss/web/ui/Text';
// @ts-ignore
import { TextInput } from '@abyss/web/ui/TextInput';
// @ts-ignore
import { Button } from '@abyss/web/ui/Button';
// @ts-ignore
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
// @ts-ignore
import { Card } from '@abyss/web/ui/Card';
// @ts-ignore
import { Alert } from '@abyss/web/ui/Alert';
// @ts-ignore
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';

export const TestPassword = () => {

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const textInputWidth =  isTabletOrMobile
        ? isPortrait
            ? 216
            : 280
        : 360;  //default

    const headingOffset = isTabletOrMobile
        ? 5   //h6 heading
        : 4;  //default, h5 heading

    const cardBorderRadius = isTabletOrMobile ? 5 : 10;
    const cardPadding = isTabletOrMobile ? 5 : 15;
    const cardHdrMarginBottom = isTabletOrMobile ? 0 : 16;
    const spaceAfterIcon = 8;

    const { user } = useAuth();
    const { t, i18n } = useTranslation();
    const [isSubmitDisabled, setSubmitDisabled] = useState(true);
    const [input, setInput] = useState({ password: '' });
    const [hasError, setError] = useState(false);
    const [isCardHidden, setCardHidden] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const toggleLoading = () => {
        setIsLoading(!isLoading);
    };

    const testPasswordForm = useForm({
        defaultValues: {
            password: '',
        },
    });

    useEffect(() => {
        // Set up where to focus when test password card is showing
        testPasswordForm.setFocus('password');
    }, [isCardHidden]);  /*apply the effect whenever isCardHidden has changed*/

    useEffect(() => {
        // Set up where to focus when set new password card is showing
        testPasswordForm.setFocus('password');
    }, [hasError]);  /*apply the effect whenever hasError has changed*/

    const onInputChange = (e: { target: { name: string; value: string; }; }) => {
        const { name, value } = e.target;
        setInput(prev => ({
            ...prev,
            [name]: value
        }));

        setSubmitDisabled(isLoading === true || !(value && value.length > 0));
    }

    const testPasswordMutation = useTestPassword();

    const onSubmit = (data: { password: string; }, e: any) => {
        toggleLoading();  //start spinner
        setSubmitDisabled(true);
        const params: PasswordParams = {
            password: btoa(data.password) //Built in function to encode in base64
        };
        testPasswordMutation.mutate(params,
            {
                onSuccess: (response) => {
                    setIsLoading(false);  //stop spinner
                    if (response.data.Response.toLowerCase() === "success") {
                        setCardHidden(true);
                        setError(false);
                        setSuccess(true);
                    } else if (response.data.Response.toLowerCase() === "invalidpassword") {
                        setCardHidden(true);
                        setError(false);
                        setSuccess(false);
                    } else {
                        setCardHidden(false);
                        setError(true);
                        setSubmitDisabled(false);
                        setSuccess(false);
                    }
                },
                onError: () => {
                    setIsLoading(false);  //stop spinner
                    setCardHidden(false);
                    setError(true);
                    setSubmitDisabled(false);
                    setSuccess(false);
                },
        });
    };

    const handleLinkClick = () => {
        setSubmitDisabled(false);
        setCardHidden(false);  //can hard-code to false here because the event handler is currently only used on the 'Try Again' link
    };

    return (
        <Layout.Stack alignLayout="center">
            {hasError &&
                <Alert
                    variant="error"
                    hideIcon
                >
                    <Layout.Stack alignLayout="center">
                        <div>
                            <IconMaterial icon="warning_amber" size="20px" color="#C40000" style={{ marginRight: `${spaceAfterIcon}`, verticalAlign: 'middle' }} />
                            <Text fontWeight="bold">{t('testPasswordError1')}</Text>
                        </div>
                        <div>
                            <Text fontWeight="bold">{t('testPasswordError2')}</Text>
                        </div>
                    </Layout.Stack>
                </Alert>
            }

            {!isCardHidden &&
                <Card style={{ border: '1 solid $gray4', boxShadow: 'none', borderRadius: `${cardBorderRadius}px`, padding: `${cardPadding}px`, margin: 4 }}>
                    <Card.Section>
                        <div style={{ border: 0, padding: `${cardPadding}px`, margin: 4, }}>
                            <Layout.Stack alignLayout="center">
                                <Heading
                                    id="testMSIDPasswordHeading"
                                    offset={headingOffset}
                                    color="black"
                                    style={{ marginBottom: `${cardHdrMarginBottom}` }}
                                >{t('testMSIDPassword')}
                                </Heading>
                                <LoadingSpinner isLoading={isLoading} ariaLoadingLabel="Testing password" />
                            </Layout.Stack>
                        </div>
                        <FormProvider state={testPasswordForm} onSubmit={onSubmit} >
                            <Layout.Stack alignLayout="center">
                                <ul>
                                    <li>
                                        <Label id="usedIdLabel" size="$md" fontWeight="bold" style={{ marginBottom: 2 }}>{user.account.userId}</Label>
                                    </li>
                                    <li>
                                        <TextInput
                                            id="passwordInput"
                                            hideLabel
                                            placeholder={t('password')}
                                            type="password"
                                            model="password"
                                            autoComplete="new-password"
                                            isDisabled={isLoading}
                                            isClearable
                                            validators={{ required: true }}
                                            onChange={onInputChange}
                                            onClear={() => setSubmitDisabled(true)}
                                            css={{
                                                'abyss-text-input': {
                                                    border: '1px solid $gray4',
                                                    minHeight: 40
                                                }
                                            }}
                                            width={textInputWidth}
                                            autoFocus    /*this will work for keyboard access, but will not set the initial focus*/
                                        />
                                    </li>
                                </ul>
                                <div style={{ border: 0, padding: 15, margin: 4, }}>
                                    <Button
                                        id="testButton"
                                        type="submit"
                                        disabled={isSubmitDisabled}
                                        size="$md"
                                        css={{
                                            'abyss-button-root': {
                                                backgroundColor: '#002677',
                                                borderColor: '#002677',
                                                color: '#FFFFFF',
                                                '&:hover': {
                                                    backgroundColor: '#D9F6FA',
                                                    borderColor: '#D9F6FA',
                                                    color: '#000000',
                                                    'box-shadow': '1px 1px 1px #dadbdc',
                                                },
                                                '&:disabled': {
                                                    backgroundColor: '#EEEEEE',
                                                    borderColor: '#EEEEEE',
                                                    color: '#999999',
                                                    'box-shadow': '1px 1px 1px #dadbdc',
                                                },
                                            },
                                        }}
                                    >{t('test')}</Button>
                                </div>
                            </Layout.Stack>
                        </FormProvider>
                    </Card.Section>
                </Card>
            }
            {isSuccess &&
                <ResultPanel
                    messageId="passwordCorrectMsg"
                    message={t('passwordCorrectMsg')}
                    icon="check_circle"
                    iconColor="green"
                    linkId="goHomeLink"
                    linkTo="/"
                    linkText={t('goHome')}
                />
            }
            {!isSuccess && isCardHidden &&
                <ResultPanel
                    messageId="passwordIncorrectMsg"
                    message={t('passwordIncorrectMsg')}
                    icon="remove_circle"
                    iconColor="red"
                    linkId="tryAgainLink"
                    linkTo="/test-password"
                    linkText={t('tryAgain')}
                    onLinkClick={handleLinkClick}
                />
            }
        </Layout.Stack>
    );
}
