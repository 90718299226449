import { PasswordParams } from '../types/PasswordParams';
import { useMutation, useQuery, useQueryClient } from "react-query";    //useQuery is used for GETs to fetch data (not used in this hook yet, just left here for now)
import axios, { AxiosError, AxiosResponse } from "axios";
import Problem from "../types/Problem";

export const useTestPassword = () => {
    const queryClient = useQueryClient();
    
    const url = 'accountmanagement/TestPassword';

    return useMutation<AxiosResponse, AxiosError<Problem>, PasswordParams>(
        (p) => axios.post(url, p, { headers: { "Content-Type": "application/json" } }),
        {
            onSuccess: (response) => {  //regardless of what's in Response, if a 200Ok is returned from the Post, code will go here. This function will fire when the mutation is successful and will be passed the mutation's result.
                queryClient.invalidateQueries("something");  //this is used to tell react-query's cache that the item called "something" here is now stale, and the next Get of that should refresh the data.  I don't think it's needed for this particular function, but left here for now for documentation.
                //note: for the best practice of "separation of concerns", any UI related actions (like navigation) should be done on the 'mutate' callback in the calling component, rather than here in the useMutation callback.
            },
            onError: () => {  //for when anything other than a 200Ok is returned.  This function will fire if the mutation encounters an error and will be passed the error.
                queryClient.invalidateQueries("something");  //see above comment on similar line of code
            },
       }
    );
};

export const useChangePassword = () => {
    const queryClient = useQueryClient();

    const url = 'accountmanagement/changepassword';

    return useMutation<AxiosResponse, AxiosError<Problem>, PasswordParams>(
        (p) => axios.post(url, p, { headers: { "Content-Type": "application/json" } }),
        {
            onSuccess: (response) => {  //regardless of what's in Response, if a 200Ok is returned from the Post, code will go here. This function will fire when the mutation is successful and will be passed the mutation's result.
                queryClient.invalidateQueries("something");  //this is used to tell react-query's cache that the item called "something" here is now stale, and the next Get of that should refresh the data.  I don't think it's needed for this particular function, but left here for now for documentation.
                //note: for the best practice of "separation of concerns", any UI related actions (like navigation) should be done on the 'mutate' callback in the calling component, rather than here in the useMutation callback.
            },
            onError: () => {  //for when anything other than a 200Ok is returned.  This function will fire if the mutation encounters an error and will be passed the error.
                queryClient.invalidateQueries("something");  //see above comment on similar line of code
            },
        }
    );
};
