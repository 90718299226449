import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// @ts-ignore
import { Layout } from '@abyss/web/ui/Layout';
// @ts-ignore
import { Text } from '@abyss/web/ui/Text';
// @ts-ignore
import { Button } from '@abyss/web/ui/Button';

export const LoggedOut = () => {

    const { t, i18n } = useTranslation();

    return (
        <div>
            <Layout.Stack alignLayout="center">
                <div style={{ border: '0px', padding: '10px', margin: '4px' }}>
                    <Text>
                        {t('loggedOutMsg')}
                    </Text>
                </div>
                <div style={{ border: '0px', padding: '5px', margin: '4px' }}>
                    <Link to="/login" style={{ border: '0px', padding: '5px', margin: '4px' }} tabIndex={-1}>
                        <Button
                            id="returnBtn"
                            size="$lg"
                            css={{
                                'abyss-button-root': {
                                    backgroundColor: '#002677',
                                    borderColor: '#002677',
                                    color: '#FFFFFF',
                                    '&:hover': {
                                        backgroundColor: '#D9F6FA',
                                        borderColor: '#D9F6FA',
                                        color: '#000000',
                                        'box-shadow': '1px 1px 1px #dadbdc',
                                    },
                                    '&:disabled': {
                                        backgroundColor: '#EEEEEE',
                                        borderColor: '#EEEEEE',
                                        color: '#999999',
                                        'box-shadow': '1px 1px 1px #dadbdc',
                                    },
                                    '&:focus': { focusRing: '$interactive1', },
                                },
                            }}
                        >{t('return')}</Button>
                    </Link>
                </div>
            </Layout.Stack>
        </div>
    );
}