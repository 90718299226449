import React from 'react';
import { useAuth } from '../context/AuthContext';
import { useTranslation } from "react-i18next";
import { LockStatusResult } from '../models/LockStatusResult';

// @ts-ignore
import { Layout } from '@abyss/web/ui/Layout';
// @ts-ignore
import { Heading } from '@abyss/web/ui/Heading';
// @ts-ignore
import { Text } from '@abyss/web/ui/Text';
// @ts-ignore
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
// @ts-ignore
import { Card } from '@abyss/web/ui/Card';

export const LockStatus = () => {
    const { t, i18n } = useTranslation();
    const { lockStatus } = useAuth();

    return (
        <div>
            {lockStatus !== LockStatusResult.Error &&
                <Layout.Stack alignLayout="center">
                    <div style={{ border: '0px', padding: '15px', margin: '4px' }}>
                        <Card style={{ boxShadow: '0px 2px 4px 0px rgba(203, 204, 205, 1)', borderRadius: 5 }}>
                            <Card.Section style={{ padding: '16px 40px' }}>

                                {
                                    (lockStatus === LockStatusResult.Pending) &&
                                    <Layout.Group alignLayout="center">
                                        <div style={{ paddingRight: '24px' }}>
                                            <LoadingSpinner ariaLoadingLabel={t('checkingPasswordStatusMsg')} size="$lg" isLoading={lockStatus === LockStatusResult.Pending} color="$warning1" />
                                        </div>
                                        <div>
                                            <Heading id="checkingPasswordStatusMsg" offset={4} color="black">{t('checkingPasswordStatusMsg')}</Heading>
                                        </div>
                                    </Layout.Group>

                                }
                                {
                                    (lockStatus === LockStatusResult.Unlocked) &&
                                    <Layout.Group alignLayout="center">
                                        <div style={{ paddingRight: '24px' }}>
                                            <img src="Lock.svg" alt="Lock" />
                                        </div>
                                        <div>
                                            <Heading id="passwordUnlockMsg1" offset={4} color="$warning1">{t('passwordUnlockMsg1')}</Heading>
                                            <Heading id="passwordUnlockMsg2" offset={4} color="$warning1">{t('passwordUnlockMsg2')}</Heading>
                                        </div>
                                    </Layout.Group>
                                }
                                {
                                    (lockStatus === LockStatusResult.NoAction) &&
                                    <Layout.Group alignLayout="center">
                                        <div style={{ paddingRight: '24px' }}>
                                            <img src="UnLock.svg" alt="Unlock" />
                                        </div>
                                        <div>
                                            <Heading id="passwordNotLockedMsg1" offset={4} color="$info1">{t('passwordNotLockedMsg1')}</Heading>
                                            <Text id="passwordNotLockedMsg2">{t('passwordNotLockedMsg2')}</Text>
                                        </div>
                                    </Layout.Group>
                                }
                            </Card.Section>
                        </Card>
                    </div>
                </Layout.Stack>
            }
        </div>
    );
}