import React, { useState, useEffect, useContext } from "react";
import { LockStatusResult } from "../models/LockStatusResult";
import { UserDetailModel } from "../models/UserDetailModel";
import { UserProfileModel } from "../models/UserProfileModel";
import axios from "axios";

export interface authContext
{
    isAuthenticated: boolean | undefined;
    isAuthError: boolean;
    user: UserProfileModel;
    passwordRules: string;
    displayName: string;
    lockStatus: LockStatusResult;
    isLoading: boolean;
    sessionTimeoutMinutes: number;
    sessionTimeoutWarningMinutes: number;
    login: () => void;
    logout: () => void;
    altlogin: () => void;
}

export const AuthContext = React.createContext<authContext>({
    isAuthenticated: false,
    isAuthError: false,
    user: new UserProfileModel(),
    passwordRules: '',
    displayName: '',
    lockStatus: LockStatusResult.Pending,
    isLoading: true,
    sessionTimeoutMinutes: 30,
    sessionTimeoutWarningMinutes: 3,
    login: function(): void {
        throw new Error("Function not implemented.");
    },
    logout: function(): void {
        throw new Error("Function not implemented.");
    },
    altlogin: function(): void {
        throw new Error("Function not implemented.");
    }
});
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: React.PropsWithChildren<{}>) => 
{
    const [isAuthError, setIsAuthError] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
    const [user, setUser] = useState<UserProfileModel>(new UserProfileModel());
    const [displayName, setDisplayName] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const [lockStatus, setLockStatus] = useState(LockStatusResult.Pending);
    const [passwordRules, setPasswordRules] = useState<string>('');
    const [sessionTimeoutMinutes, setSessionTimeoutMinutes] = useState<number>(30);
    const [sessionTimeoutWarningMinutes, setSessionTimeoutWarningMinutes] = useState<number>(3);

    const getUser = async () => 
    {
        var userDetail: UserDetailModel = new UserDetailModel();

        try {
            const response = axios.post('/auth/getUser', { headers: { "Content-Type": "application/json" } });
            userDetail = await (await response).data;

            if (userDetail.isAuthenticated === true && (userDetail.userProfile === null || (userDetail.userProfile !== null && userDetail.userProfile.account === null))) {
                setIsAuthError(true);
            }
        }
        catch (error) {
            setIsAuthError(true);
        }

        setIsAuthenticated(userDetail.isAuthenticated);

        setSessionTimeoutMinutes(userDetail.sessionTimeoutMinutes);
        setSessionTimeoutWarningMinutes(userDetail.sessionTimeoutWarningMinutes);

        if (userDetail.isAuthenticated === true) {
            if (userDetail.userProfile !== null) {
                setUser(userDetail.userProfile);
                setDisplayName(userDetail.userProfile.fullName.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' '));
                unlockAccount();
            }

            if (userDetail.passwordRules !== null) {
                setPasswordRules(userDetail.passwordRules);
            }
        }

        setIsLoading(false);
    }

    const unlockAccount = async () => {
        try {
            const response = axios.post('/accountmanagement/unlockaccount', { headers: { "Content-Type": "application/json" } });
            const data: string = await (await response).data;

            switch (data.toLowerCase()) {
                case "not locked": {
                    setLockStatus(LockStatusResult.NoAction);
                    break;
                }
                case "unlocked": {
                    setLockStatus(LockStatusResult.Unlocked);
                    break;
                }
                default: {
                    setLockStatus(LockStatusResult.Error);
                    break;
                }
            }
        } catch (error) {
            setLockStatus(LockStatusResult.Error);
        }
    }

    useEffect(() => {
        getUser();
    }, []);

    const login = () => {
        window.location.href = '/auth/login';
    }

    const altlogin = () => {
        window.location.href = '/auth/altlogin';
    }

    const logout = () => {
        window.location.href = '/auth/logout';
    }

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            isAuthError,
            user,
            passwordRules,
            displayName,
            lockStatus,
            isLoading,
            sessionTimeoutMinutes,
            sessionTimeoutWarningMinutes,
            login,
            logout,
            altlogin
        }}> {children}
        </AuthContext.Provider>
    );
};