import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useAuth } from '../context/AuthContext';
import { useTranslation } from "react-i18next";
import { useChangePassword } from '../hooks/PasswordHooks';
import { PasswordParams } from '../types/PasswordParams';
import { ResultPanel } from './ResultPanel'

// @ts-ignore
import { useForm } from '@abyss/web/hooks/useForm';
// @ts-ignore
import { FormProvider } from '@abyss/web/ui/FormProvider'
// @ts-ignore
import { Layout } from '@abyss/web/ui/Layout';
// @ts-ignore
import { Heading } from '@abyss/web/ui/Heading';
// @ts-ignore
import { Label } from '@abyss/web/ui/Label';
// @ts-ignore
import { Text } from '@abyss/web/ui/Text';
// @ts-ignore
import { TextInput } from '@abyss/web/ui/TextInput';
// @ts-ignore
import { Button } from '@abyss/web/ui/Button';
// @ts-ignore
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
// @ts-ignore
import { Card } from '@abyss/web/ui/Card';
// @ts-ignore
import { Alert } from '@abyss/web/ui/Alert';
// @ts-ignore
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';

const minimumLengthDefault = 14;

export const NewPassword = () => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const textInputWidth = isTabletOrMobile
        ? isPortrait
            ? 216
            : 280
        : 360;  //default

    const headingOffset = isTabletOrMobile
        ? 5   //h6 heading
        : 4;  //default, h5 heading

    const cardBorderRadius = isTabletOrMobile ? 5 : 10;
    const cardPadding = isTabletOrMobile ? 5 : 15;
    const textSize = isTabletOrMobile ? "$xs" : "$md";
    const lineHeight = isTabletOrMobile ? 16 : 24;
    const spaceAfterIcon = 8;

    const { user, passwordRules } = useAuth();
    const { t, i18n } = useTranslation();

    const [validMatch, setValidMatch] = useState(true);
    const [validMinimumLength, setValidMinimumLength] = useState(false);
    const [validNoAccountName, setValidNoAccountName] = useState(true);
    const [validNoFullName, setValidNoFullName] = useState(true);
    const [validNotAllNumeric, setValidNotAllNumeric] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [hasError, setError] = useState(false);
    const [hasWarning, setWarning] = useState(false);
    const [isCardHidden, setCardHidden] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [input, setInput] = useState({
        password: '',
        confirmPassword: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const toggleLoading = () => {
        setIsLoading(!isLoading);
    };

    const setNewPasswordForm = useForm({
        defaultValues: {
            password: '',
            confirmPassword: '',
        },
    });

    const [minimumLength, setMinimumLength] = useState(minimumLengthDefault);

    useEffect(() => {
        if (minimumLength === minimumLengthDefault) {
            processPasswordRules();
        }
    }, []);   /*empty array as 2nd parameter means it should only run after the initial render*/

    useEffect(() => {
        // Set up where to focus when set new password card is showing
        setNewPasswordForm.setFocus('password');
    }, [hasError]);  /*apply the effect whenever hasError has changed*/

    useEffect(() => {
        // Set up where to focus when set new password card is showing
        setNewPasswordForm.setFocus('password');
    }, [hasWarning]);  /*apply the effect whenever hasWarning has changed*/

    const processPasswordRules = () => {
        if (passwordRules !== undefined && passwordRules !== '') {
            if (passwordRules.indexOf('|') >= 0) {
                let rules = passwordRules.split('|');
                let i = 0;
                while (i < rules.length) {
                    if (rules[i].indexOf('=') >= 0) {
                        let rule = rules[i].split('=');
                        if (rule[0].toLowerCase() === 'minimumlength') {
                            setMinimumLength(Number(rule[1]));
                            break;
                        }
                    }
                    i++;
                }
            }
        }
    };

    const handlePasswordChange = (event: any) => {
        const { name, value } = event.target;
        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(event);
    };

    const validateInput = (e: { target: { name: string; value: string; }; }) => {
        let { name, value } = e.target;

        switch (name) {
            case "password":
                setValidMinimumLength(MinimumLength(value));
                setValidNoAccountName(NoAccountName(value));
                setValidNoFullName(NoFullName(value));
                setValidNotAllNumeric(NotAllNumeric(value));

                if (value === input.confirmPassword) {
                    setValidMatch(true);
                    if (MinimumLength(value) === true && validNoAccountName === true && validNoFullName === true && validNotAllNumeric === true) {
                        setIsValid(true);
                    } else {
                        setIsValid(false);
                    }
                } else {
                    setValidMatch(false);
                    setIsValid(false);
                }
                break;

            case "confirmPassword":
                if (value === input.password) {
                    setValidMatch(true);
                    if (validMinimumLength === true && validNoAccountName === true && validNoFullName === true && validNotAllNumeric === true) {
                        setIsValid(true);
                    } else {
                        setIsValid(false);
                    }
                }
                else {
                    setValidMatch(false);
                    setIsValid(false);
                }
                break;

            default:
                break;
        }
    }

    function MinimumLength(password: string) {
        if (password.length >= minimumLength) {
            return true;
        }

        return false;
    }

    function NoAccountName(password: string) {
        if (password.toLowerCase().includes(user.account.userId.toLowerCase())) {
            return false;
        }

        return true;
    }

    function NoFullName(password: string) {
        if (password.toLowerCase().includes(user.firstName.toLowerCase()) || password.toLowerCase().includes(user.lastName.toLowerCase())) {
            return false;
        }

        return true;
    }

    function NotAllNumeric(password: string) {
        let numbers = /^[0-9]+$/;
        if (numbers.test(password)) {
            return false;
        }

        return true;
    }

    const handlePasswordInputOnClear = () => {
        setInput({ password: '', confirmPassword: input.confirmPassword });
        setValidMatch(input.confirmPassword.length === 0);
        setValidMinimumLength(false);
        setValidNoAccountName(true);
        setValidNoFullName(true);
        setValidNotAllNumeric(true);
        setIsValid(false);
    };

    const handleConfirmPasswordInputOnClear = () => {
        setInput({ password: input.password, confirmPassword: '' });
        setValidMatch(input.password.length === 0);
        setIsValid(false);
    };

    const changePasswordMutation = useChangePassword();

    const onNewPasswordSubmit = (data: { password: string; }, e: any) => {
        toggleLoading();  //start spinner
        const params: PasswordParams = {
            password: btoa(data.password) //Encode the password with base64 encoding
        };
        changePasswordMutation.mutate(params,
            {
                onSuccess: (response) => {
                    setIsLoading(false);  //stop spinner
                    if (response.data.Response.toLowerCase() === "success") {
                        setError(false);
                        setWarning(false);
                        setCardHidden(true);
                        setSuccess(true);
                    } else if (response.data.Response.toLowerCase() === "invalidpassword") {
                        setError(false);
                        setWarning(true);
                    } else {
                        setError(true);
                        setWarning(false);
                    }
                },
                onError: () => {
                    setIsLoading(false);  //stop spinner
                    setError(true);
                    setWarning(false);
                },
            });
    };

    return (
        <Layout.Stack alignLayout="center">

            {hasWarning &&
                <Alert
                    variant="warning"
                    hideIcon
                >
                    <Layout.Stack alignLayout="center">
                        <div>
                            <IconMaterial icon="warning_amber" size="20px" color="#C24E14" style={{ marginRight: `${spaceAfterIcon}`, verticalAlign: 'middle' }} />
                            <Text>{t('newPasswordWarningPrompt')}</Text>
                        </div>
                        <div>
                            <Text fontWeight="bold">{t('newPasswordWarning1')}</Text>
                        </div>
                        <div>
                            <Text fontWeight="bold">{t('newPasswordWarning2')}</Text>
                        </div>
                    </Layout.Stack>
                </Alert>
            }

            {hasError &&
                <Alert
                    variant="error"
                    hideIcon
                >
                    <Layout.Stack alignLayout="center">
                        <div>
                            <IconMaterial icon="warning_amber" size="20px" color="#C40000" style={{ marginRight: `${spaceAfterIcon}`, verticalAlign: 'middle' }} />
                            <Text fontWeight="bold">{t('newPasswordError1')}</Text>
                        </div>
                        <div>
                            <Text fontWeight="bold">{t('newPasswordError2')}</Text>
                        </div>
                    </Layout.Stack>
                </Alert>
            }
            
            {!isCardHidden &&
                <Card style={{ border: '1 solid $gray4', boxShadow: 'none', borderRadius: `${cardBorderRadius}px`, padding: `${cardPadding}px`, margin: 4 }}>
                    <Card.Section>
                        <div style={{ border: 0, padding: `${cardPadding}px`, margin: 4, }}>
                            <Layout.Stack alignLayout="center">
                                <Heading id="setNewPasswordHeading" offset={headingOffset} color="$black">{t('setNewPassword')}</Heading>
                                <ul>
                                    <li style={{ textAlign: 'center', lineHeight: `${lineHeight}px` }}>
                                        <Text id="passwordRulesPrompt" size={textSize}>{t('passwordRulesPrompt')}</Text>
                                    </li>
                                    <li style={{ lineHeight: `${lineHeight}px` }}>
                                        <IconMaterial icon="circle" size="5px" color="black" style={{ marginRight: `${spaceAfterIcon}`, verticalAlign: 'middle' }} />
                                        <Text id="passwordRules1" size={textSize}>{t('passwordRules1')}</Text>
                                    </li>
                                    <li style={{ lineHeight: `${lineHeight}px` }}>
                                        <IconMaterial icon="circle" size="5px" color="black" style={{ marginRight: `${spaceAfterIcon}`, verticalAlign: 'middle' }} />
                                        <Text id="passwordRules2" size={textSize}>{t('passwordRules2')}</Text>
                                    </li>
                                </ul>
                                <LoadingSpinner isLoading={isLoading} ariaLoadingLabel="Changing password" />
                            </Layout.Stack>
                        </div>
                        <FormProvider state={setNewPasswordForm} onSubmit={onNewPasswordSubmit}>
                            <Layout.Stack alignLayout="center">
                                <ul>
                                    <li>
                                        <Label id="userIdLabel" size="$md" fontWeight="bold" style={{ marginBottom: 2 }}>{user.account.userId}</Label>
                                    </li>
                                    <li>
                                        <TextInput
                                            id="passwordInput"
                                            hideLabel
                                            type="password"
                                            placeholder={t('newPassword')}
                                            model="password"
                                            isDisabled={isLoading}
                                            validators={{
                                                required: true,
                                            }}
                                            onChange={handlePasswordChange}
                                            isClearable
                                            onClear={handlePasswordInputOnClear}
                                            css={{
                                                'abyss-text-input': {
                                                    border: '1px solid $gray4',
                                                    minHeight: 40
                                                }
                                            }}
                                            width={textInputWidth}
                                            autoFocus
                                        />
                                        <TextInput
                                            id="confirmPasswordInput"
                                            hideLabel
                                            type="password"
                                            placeholder={t('confirmedNewPassword')}
                                            model="confirmPassword"
                                            isDisabled={isLoading}
                                            validators={{
                                                required: true,
                                            }}
                                            onChange={handlePasswordChange}
                                            isClearable
                                            onClear={handleConfirmPasswordInputOnClear}
                                            css={{
                                                'abyss-text-input': {
                                                    border: '1px solid $gray4',
                                                    minHeight: 40
                                                }
                                            }}
                                            width={textInputWidth}                                       
                                        />
                                    </li>
                                </ul>
                                <ul>
                                    <ul>
                                        {!validMatch &&
                                            <li style={{ lineHeight: `${lineHeight}px` }}>
                                                <IconMaterial icon="close" size="sm" color="#C40000" style={{ marginRight: `${spaceAfterIcon}`, verticalAlign: 'bottom' }} />
                                                <Text id="validationMatchRed" color="red" size={textSize}>{t('validationMatch')}</Text></li>}
                                        {!validMinimumLength &&
                                            <li style={{ lineHeight: `${lineHeight}px` }}>
                                                <IconMaterial icon="close" size="sm" color="#C40000" style={{ marginRight: `${spaceAfterIcon}`, verticalAlign: 'bottom' }} />
                                                <Text id="validationMinLengthRed" color="red" size={textSize}>{t('validationMinLength', { minimum: minimumLength })}</Text></li>}
                                        {!validNoAccountName &&
                                            <li style={{ lineHeight: `${lineHeight}px` }}>
                                                <IconMaterial icon="close" size="sm" color="#C40000" style={{ marginRight: `${spaceAfterIcon}`, verticalAlign: 'bottom' }} />
                                                <Text id="validationUserIdhRed" color="red" size={textSize}>{t('validationUserId')}</Text></li>}
                                        {!validNoFullName &&
                                            <li style={{ lineHeight: `${lineHeight}px` }}>
                                                <IconMaterial icon="close" size="sm" color="#C40000" style={{ marginRight: `${spaceAfterIcon}`, verticalAlign: 'bottom' }} />
                                                <Text id="validationNameRed" color="red" size={textSize}>{t('validationName')}</Text></li>}
                                        {!validNotAllNumeric &&
                                            <li style={{ lineHeight: `${lineHeight}px` }}>
                                                <IconMaterial icon="close" size="sm" color="#C40000" style={{ marginRight: `${spaceAfterIcon}`, verticalAlign: 'bottom' }} />
                                                <Text id="validationNumericRed" color="red" size={textSize}>{t('validationNumeric')}</Text></li>}

                                        {validMinimumLength &&
                                            <li style={{ lineHeight: `${lineHeight}px` }}>
                                                <IconMaterial icon="check" size="sm" color="#007000" style={{ marginRight: `${spaceAfterIcon}`, verticalAlign: 'bottom' }} />
                                                <Text id="validationMinLengthGreen" color="green" size={textSize}>{t('validationMinLength', { minimum: minimumLength })}</Text></li>}
                                        {validNoAccountName &&
                                            <li style={{ lineHeight: `${lineHeight}px` }}>
                                                <IconMaterial icon="check" size="sm" color="#007000" style={{ marginRight: `${spaceAfterIcon}`, verticalAlign: 'bottom' }} />
                                                <Text id="validationUserIdGreen" color="green" size={textSize}>{t('validationUserId')}</Text></li>}
                                        {validNoFullName &&
                                            <li style={{ lineHeight: `${lineHeight}px` }}>
                                                <IconMaterial icon="check" size="sm" color="#007000" style={{ marginRight: `${spaceAfterIcon}`, verticalAlign: 'bottom' }} />
                                                <Text id="validationNameGreen" color="green" size={textSize}>{t('validationName')}</Text></li>}
                                        {validNotAllNumeric &&
                                            <li style={{ lineHeight: `${lineHeight}px` }}>
                                                <IconMaterial icon="check" size="sm" color="#007000" style={{ marginRight: `${spaceAfterIcon}`, verticalAlign: 'bottom' }} />
                                                <Text id="validationNumericGreen" color="green" size={textSize}>{t('validationNumeric')}</Text></li>}
                                    </ul>
                                </ul>
                                <div style={{ border: 0, padding: 15, margin: 4, }}>
                                    <Button
                                        id="submitButton"
                                        type="submit"
                                        disabled={isLoading || !isValid}
                                        size="$md"
                                        css={{
                                            'abyss-button-root': {
                                                backgroundColor: '#002677',
                                                borderColor: '#002677',
                                                color: '#FFFFFF',
                                                '&:hover': {
                                                    backgroundColor: '#D9F6FA',
                                                    borderColor: '#D9F6FA',
                                                    color: '#000000',
                                                    'box-shadow': '1px 1px 1px #dadbdc',
                                                },
                                                '&:disabled': {
                                                    backgroundColor: '#EEEEEE',
                                                    borderColor: '#EEEEEE',
                                                    color: '#999999',
                                                    'box-shadow': '1px 1px 1px #dadbdc',
                                                },
                                            },
                                        }}
                                    >{t('submit')}</Button>
                                </div>
                            </Layout.Stack>
                        </FormProvider>
                    </Card.Section>
                </Card>
            }

            {isSuccess &&
                <ResultPanel
                    messageId="passwordSetMsg"
                    message={t('passwordSetMsg')}
                    icon="check_circle"
                    iconColor="green"
                    linkId="goHomeLink"
                    linkTo="/"
                    linkText={t('goHome')}
                />
            }

        </Layout.Stack>
    );
}
