import { useEffect } from 'react';
import { useAuth } from '../context/AuthContext';

export const AltLogin = () => {
    const { altlogin } = useAuth();

    useEffect(() => {
        altlogin();
    }, [altlogin]);

    return (<div></div>);
}
