import React, { useState, useEffect } from "react";
import { Route, Routes } from 'react-router-dom';
import { useCountdown } from 'usehooks-ts'
import { DateTime } from "luxon";
import axios from "axios";

import { Layout as MasterLayout } from './components/Layout';
import { Home } from "./components/Home";
import { Login } from "./components/Login";
import { AltLogin } from "./components/AltLogin";
import { Logout } from "./components/Logout";
import { LoggedOut } from "./components/LoggedOut";
import { NewPassword } from "./components/NewPassword";
import { TestPassword } from "./components/TestPassword";
import { LockStatusResult } from "./models/LockStatusResult";

import { useAuth } from './context/AuthContext';

// @ts-ignore
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
// @ts-ignore
import { createTheme } from '@abyss/web/tools/theme';
// @ts-ignore
import { Modal } from '@abyss/web/ui/Modal';
// @ts-ignore
import { Button } from '@abyss/web/ui/Button';
// @ts-ignore
import { Layout } from '@abyss/web/ui/Layout';
// @ts-ignore
import { Text } from '@abyss/web/ui/Text';
// @ts-ignore
import { IconMaterial } from '@abyss/web/ui/IconMaterial';

import { I18nextProvider, i18n } from './utils/i18n/i18n';
import { useTranslation } from "react-i18next";

import './custom.css';

// @ts-ignore
const themeOverride = {
    theme: {
        //colors: { ...},
        //space: { ...},
        //fontSizes: { ...},
        //fonts: { ...},
        //fontWeights: { ...},
        //lineHeights: { ...},
        //letterSpacings: { ...},
        //sizes: { ...},
        //borderWidths: { ...},
        //borderStyles: { ...},
        //radii: { ...},
        //shadows: { ...},
        //zIndices: { ...},
        //transitions: { ...},
    },
    /*    themeCss: { ...},*/
    css: {
        '.abyss-page-header-logo-title': {
            color: '#5A5A5A !important',
        },
        '.abyss-page-header-logo': {
            color: '#5A5A5A !important',
        },
        '.abyss-nav-menu-item-title': {
            color: 'black !important',
        },
    },
};

const theme = createTheme('optum', themeOverride);

const App = () => {
    const { isAuthenticated, isAuthError, lockStatus, sessionTimeoutMinutes, sessionTimeoutWarningMinutes, login, logout, altlogin } = useAuth();
    const { t, i18n } = useTranslation();

    const [sessionExpiration, setSessionExpiration] = useState<DateTime>(DateTime.now().plus({ minutes: 2 }));
    const [sessionWarning, setSessionWarning] = useState<DateTime>(DateTime.now().plus({ minutes: 1 }));
    const [isTimeoutModalOpen, setIsTimeoutModalOpen] = useState(false);
    const [isUnlockFailedModalOpen, setIsUnlockFailedModalOpen] = useState(false);
    const [isAuthErrorModalOpen, setIsAuthErrorModalOpen] = useState(false);
    const [isWarned, setWarned] = useState(false);
    const [isComplete, setComplete] = useState(false);
    const [sessionLocation, setSessionLocation] = useState('');

    const [count, { startCountdown, stopCountdown, resetCountdown }] =
        useCountdown({
            countStart: sessionTimeoutMinutes * 60,
            intervalMs: 1000
        });

    const isNotLogout = window.location.pathname.toLowerCase() !== '/logout' && window.location.pathname.toLowerCase() !== '/loggedout';

    const extendSession = async () => {
        const response = axios.post('/auth/extendsession', { headers: { "Content-Type": "application/json" } });
        var success: boolean = await (await response).data;
    }

    useEffect(() => {
        setSessionExpiration(DateTime.now().plus({ minutes: sessionTimeoutMinutes }));
        setSessionWarning(DateTime.now().plus({ minutes: sessionTimeoutMinutes - sessionTimeoutWarningMinutes }));
    }, [sessionTimeoutMinutes, sessionTimeoutWarningMinutes]);

    useEffect(() => {
        if (isAuthError === true) {
            setIsAuthErrorModalOpen(true);
        }
    }, [isAuthError]);

    useEffect(() => {
        if (lockStatus === LockStatusResult.Error) {
            setIsUnlockFailedModalOpen(true);
        }
    }, [lockStatus]);

    if (isAuthenticated && isComplete === false && (count === 0 || DateTime.now() > sessionExpiration)) {
        setComplete(true);
        stopCountdown();
        logout();
    }

    if (isAuthenticated !== undefined && isAuthenticated !== true) {
        if (window.location.pathname.toLowerCase() === '/altlogin') {
            altlogin();
        } else if (isNotLogout) {
            login();
        }
    } else {
        if (sessionLocation !== window.location.pathname) {
            setSessionLocation(window.location.pathname);
            if (isNotLogout) {
                ContinueSession();
            }
        }
    }

    if (isAuthenticated && isComplete === false && isWarned === false && isTimeoutModalOpen === false) {
        if (DateTime.now() > sessionWarning && isWarned === false) {
            setWarned(true);
            setIsTimeoutModalOpen(true);
        }
    }

    function ContinueSession() {
        resetCountdown();
        startCountdown();
        setWarned(false);
        setIsTimeoutModalOpen(false);
        setSessionExpiration(DateTime.now().plus({ minutes: sessionTimeoutMinutes }));
        setSessionWarning(DateTime.now().plus({ minutes: sessionTimeoutMinutes - sessionTimeoutWarningMinutes }));
        extendSession();
    }

    return (
        <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
                <MasterLayout>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/home' element={<Home />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/altlogin' element={<AltLogin />} />
                        <Route path='/logout' element={<Logout />} />
                        <Route path='/loggedout' element={<LoggedOut />} />
                        {isAuthenticated &&
                            <Route path='/new-password' element={<NewPassword />} />
                        }
                        {isAuthenticated &&
                            <Route path='/test-password' element={<TestPassword />} />
                        }
                    </Routes>

                    <React.Fragment>

                        <Modal
                            closeOnClickOutside={false}
                            isOpen={isTimeoutModalOpen}
                            onClose={() => setIsTimeoutModalOpen(false)}
                            footer={
                                <Modal.Section>
                                    <Layout.Group alignLayout="center">
                                        <Button id="continueSession" onClick={() => ContinueSession()} autoFocus>{t('continue')}</Button>
                                    </Layout.Group>
                                </Modal.Section>
                            }
                        >
                            <Modal.Section>
                                <Layout.Group>
                                    <div style={{ padding: '35px', margin: '5px', background: '#FCF0F0', borderRadius: '50%', alignItems: 'center', display: 'flex' }}>
                                        <IconMaterial icon="error" size="$lg" color="#C40000" />
                                    </div>
                                    <div>
                                        <Layout.Stack alignItems="left">
                                            <div>
                                                <Text id="warning" size="30px" fontWeight="bold" color="$black">{t('warning')}</Text>
                                            </div>
                                            <div>
                                                <Text id="sessionTimeoutMsg" size="22px" color="$black">{t('sessionTimeoutMsg')}</Text>
                                            </div>
                                        </Layout.Stack>
                                    </div>
                                </Layout.Group>
                            </Modal.Section>
                        </Modal>

                        <Modal
                            closeOnClickOutside={true}
                            isOpen={isUnlockFailedModalOpen}
                            onClose={() => setIsUnlockFailedModalOpen(false)}
                        >
                            <Modal.Section>
                                <Layout.Group>
                                    <div style={{ padding: '35px', margin: '5px', background: '#FCF0F0', borderRadius: '50%', alignItems: 'center', display: 'flex' }}>
                                        <IconMaterial icon="error" size="$lg" color="#C40000" />
                                    </div>
                                    <div>
                                        <Layout.Stack alignItems="left">
                                            <div>
                                                <Text id="unlockFailedTitle" size="30px" fontWeight="bold" color="$black">{t('unlockFailedTitle')}</Text>
                                            </div>
                                            <div>
                                                <Text id="unlockFailedMsg" size="22px" color="$black">{t('unlockFailedMsg')}</Text>
                                            </div>
                                        </Layout.Stack>
                                    </div>
                                </Layout.Group>
                            </Modal.Section>
                        </Modal>

                        <Modal
                            closeOnClickOutside={true}
                            isOpen={isAuthErrorModalOpen}
                            onClose={() => setIsAuthErrorModalOpen(false)}
                        >
                            <Modal.Section>
                                <Layout.Group>
                                    <div style={{ padding: '35px', margin: '5px', background: '#FCF0F0', borderRadius: '50%', alignItems: 'center', display: 'flex' }}>
                                        <IconMaterial icon="error" size="$lg" color="#C40000" />
                                    </div>
                                    <div>
                                        <Layout.Stack alignItems="left">
                                            <div>
                                                <Text id="accountFailedTitle" size="30px" fontWeight="bold" color="$black">{t('accountFailedTitle')}</Text>
                                            </div>
                                            <div>
                                                <Text id="accountFailedMsg" size="22px" color="$black">{t('accountFailedMsg')}</Text>
                                            </div>
                                        </Layout.Stack>
                                    </div>
                                </Layout.Group>
                            </Modal.Section>
                        </Modal>

                    </React.Fragment>

                </MasterLayout>
            </ThemeProvider>
        </I18nextProvider>
    );
}

export default App;
