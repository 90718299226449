import React from 'react';
import { Link } from 'react-router-dom';

// @ts-ignore
import { Layout } from '@abyss/web/ui/Layout';
// @ts-ignore
import { Heading } from '@abyss/web/ui/Heading';
// @ts-ignore
import { IconMaterial } from '@abyss/web/ui/IconMaterial';

type ResultProps = {
    messageId: string,  /* used for id attribute in the html */
    message: string,    /* should be localized prior to sending here */
    icon: string,       /* assumes icon is from Abyss for IconMaterial component*/
    iconColor: string,
    linkId: string,     /* used for id attribute in the html */
    linkText: string,   /* should be localized prior to sending here */
    linkTo: string,
    onLinkClick?: any   /* this should be a function passed in (not a call to a function) */
};

export const ResultPanel = ({
    messageId,
    message,
    icon,
    iconColor,
    linkId,
    linkTo,
    linkText,
    onLinkClick
}: ResultProps) => {
    return (
        <div style={{ border: 0, padding: 15, margin: 4, }}>
            <Layout.Stack alignLayout="center">
                <div style={{ border: 0, padding: 15, margin: 4, marginBottom: 12.5, textAlign: 'center' }}>
                    <Heading id={messageId} offset={4} color="black">{message}</Heading>
                </div>
                <div style={{ border: 0, padding: 15, margin: 4, }}>
                    <IconMaterial icon={icon} size="72px" color={iconColor} />
                </div>
                <div style={{ border: 0, padding: 15, margin: 4, }}>
                    <Link id={linkId} to={linkTo} onClick={onLinkClick} >{linkText}</Link>
                </div>
            </Layout.Stack>
        </div>
    );
}
