import { useEffect } from 'react';
import { useAuth } from '../context/AuthContext';

export const Login = () => {
    const { login } = useAuth();

    useEffect(() => {
        login();
    }, [login]);

    return (<div></div>);
}
