import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { useAuth } from '../context/AuthContext';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

// @ts-ignore
import { Layout } from '@abyss/web/ui/Layout';
// @ts-ignore
import { Heading } from '@abyss/web/ui/Heading';
// @ts-ignore
import { Text } from '@abyss/web/ui/Text';
// @ts-ignore
import { Button } from '@abyss/web/ui/Button';

export const Home = () => {

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const welcomeHeadingOffset = isTabletOrMobile
        ? isPortrait
            ? 4   //h5 heading
            : 2   //h3 heading
        : 0;  //default, 0 is an h1 heading

    const welcomeMsgSize = isTabletOrMobile
        ? isPortrait
            ? 16  //in px
            : 18
        : 22;  //default

    const welcomeMsgWeight = isTabletOrMobile ? 400 : 600;

    const { displayName, isAuthError } = useAuth();
    const { t, i18n } = useTranslation();

    return (
        <Layout.Stack alignLayout="center">
            {!isAuthError &&
                <div style={{ border: '0px', padding: '15px', margin: '4px' }}>
                    <Heading offset={welcomeHeadingOffset} color="black" id="welcomeHeading" textAlign="center">{t('welcome', { fullName: displayName })}</Heading>
                </div>
            }

            {!isAuthError &&
                <div style={{ border: '0px', padding: '10px', margin: '0px' }}>
                    <Text color="black" id="welcomeMsg" size={welcomeMsgSize} fontWeight={welcomeMsgWeight}>{t('welcomeMsg')}</Text>
                </div>
            }

            {!isAuthError &&
                <div style={{ border: '0px', padding: '4px', margin: '0px' }}>
                    <Link to="/new-password" style={{ border: '0px', padding: '5px', margin: '4px' }} tabIndex={-1}>
                        <Button
                            id="setNewMSIDPasswordBtn"
                            size="$lg"
                            css={{
                                'abyss-button-root': {
                                    backgroundColor: '#002677',
                                    borderColor: '#002677',
                                    color: '#FFFFFF',
                                    '&:hover': {
                                        backgroundColor: '#D9F6FA',
                                        borderColor: '#D9F6FA',
                                        color: '#000000',
                                        'box-shadow': '1px 1px 1px #dadbdc',
                                    },
                                    '&:disabled': {
                                        backgroundColor: '#EEEEEE',
                                        borderColor: '#EEEEEE',
                                        color: '#999999',
                                        'box-shadow': '1px 1px 1px #dadbdc',
                                    },
                                    '&:focus': { focusRing: '$interactive1', },
                                },
                            }}
                            autoFocus
                        >{t('setNewMSIDPassword')}</Button>
                    </Link>
                </div>
            }

            {!isAuthError &&
                <div style={{ border: '0px', padding: '4px', margin: '0px' }}>
                    <Link to="/test-password" style={{ border: '0px', padding: '5px', margin: '4px' }} tabIndex={-1}>
                        <Button
                            id="testMSIDPasswordBtn"
                            size="$lg"
                            variant="outline"
                            css={{
                                'abyss-button-root': {
                                    backgroundColor: '#FFFFFF',
                                    borderColor: '#002677',
                                    color: '#002677',
                                    '&:hover': {
                                        backgroundColor: '#D9F6FA',
                                        borderColor: '#002677',
                                        color: '#002677',
                                        'box-shadow': '1px 1px 1px #dadbdc',
                                    },
                                    '&:disabled': {
                                        backgroundColor: '#EEEEEE',
                                        borderColor: '#EEEEEE',
                                        color: '#999999',
                                        'box-shadow': '1px 1px 1px #dadbdc',
                                    },
                                },
                            }}
                        >{t('testMSIDPassword')}</Button>
                    </Link>
                </div>
            }
        </Layout.Stack>
    );
}
