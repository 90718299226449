import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import XHR from "i18next-http-backend"
import translationsEnJson from '../../locale/en/translationEN.json';
import translationsEsJson from '../../locale/es/translationES.json';

const resources = {
    en: { translation: translationsEnJson },
    es: { translation: translationsEsJson },
}

const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng'
}

i18n
    .use(XHR)
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn

    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        load: 'languageOnly',
        detection: options,
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        debug: false,
    });

export { i18n, I18nextProvider };