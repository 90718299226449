import { PropsWithChildren } from 'react'
import { Container } from 'reactstrap';
import { useAuth } from '../context/AuthContext';
import { HeaderNavMenu } from './HeaderNavMenu';
import { Footer } from './Footer';
import { LockStatus } from './LockStatus';

export const Layout = (props: PropsWithChildren) => {
    const { isAuthenticated, isAuthError } = useAuth();

    return (
        <div>
            {
                isAuthenticated === true &&
                <div style={{
                    display: 'flex',
                    flexFlow: 'column',
                    height: '100vh'
                }}>
                    <HeaderNavMenu />
                    <Container style={{
                        display: 'flex',
                        flexFlow: 'column',
                        flexGrow: 1,
                        flexShrink: 1,
                        flexBasis: 'auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        {props.children}
                    </Container>
                    {!isAuthError &&
                        <LockStatus />
                    }
                    <Footer />
                </div>
            }
            {
                (window.location.pathname.toLowerCase() === '/logout' || window.location.pathname.toLowerCase() === '/loggedout') &&
                <div style={{
                    display: 'flex',
                    flexFlow: 'column',
                    height: '100vh'
                }}>
                    <HeaderNavMenu />
                    <Container style={{
                        display: 'flex',
                        flexFlow: 'column',
                        flexGrow: 1,
                        flexShrink: 1,
                        flexBasis: 'auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        {props.children}
                    </Container>
                    <Footer />
                </div>
            }
        </div>
    );
}
